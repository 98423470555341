<template>
  <li class="sgl-player-panel">
    <div class="place">{{ getRatingPlace }}.</div>
    <router-link :to="{name: 'another-profile', params: {id: getUserId}}">
      <img class="avatar" :src="getLogo" alt="#">
    </router-link>
    <div class="info">
      <router-link :to="{name: 'another-profile', params: {id: getUserId}}">
        <div class="name">{{getLogin}}</div>
      </router-link>
      <div class="nickname">{{getFirstAndLastname}}</div>
    </div>
    <div class="exp">{{getRating}} exp</div>
  </li>
</template>

<script>
export default {
  name: "ratingOfParticipant",
  props: {
    rating: Object,
  },
  computed: {
    getUserId() {
      return this.rating.id
    },

    getRatingPlace() {
      return this.rating.place
    },

    getLogo() {
      return this.rating.logo
    },

    getRating() {
      return this.rating.rating.toLocaleString()
    },

    getLogin() {
      return this.rating.login
    },

    getFirstAndLastname() {
      return `${this.rating.firstName}  ${this.rating.lastName}`
    }
  }
}
</script>

<style scoped>

</style>