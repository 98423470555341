<template>
  <a :href="this.link" target="_blank" class="sponsor-banner__wrapper" :class="getPositionClass">
    <picture>
      <source :srcset="this.images.mobile.optimizedUrl" media="(max-width: 767px)" type="image/webp">
      <source :srcset="this.images.mobile.url" media="(max-width: 767px)">
      <source :srcset="this.images.tablet.optimizedUrl" media="(max-width: 1024px)" type="image/webp">
      <source :srcset="this.images.tablet.url" media="(max-width: 1024px)">
      <source :srcset="this.images.desktop.optimizedUrl" type="image/webp">
      <img class="sponsor-banner__img" :src="this.images.desktop.url" alt="#">
    </picture>
  </a>
</template>

<script>

export default {
  name: "bannerComponent",
  props: {
    images: {
      desktop: String,
      mobile: String,
      tablet: String
    },
    link: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      default: 'top'
    }
  },
  computed: {
    /**
     * Возвращаем класс позиционирования блока
     * */
    getPositionClass() {
      return `sponsor-banner__${this.position}`;
    }
  }
}
</script>

<style scoped>

</style>
